
import { createRouter, createWebHashHistory  } from 'vue-router'


const routes = [
  { path: '/',
    redirect: "/pay",
  },
  { path: '/comparison', component: ()=>import('../views/comparison.vue'),},
  // { path: '/signin', component: ()=>import('../views/signin.vue'),},
  { path: '/pay/:token', component: ()=>import('../views/pay.vue'),},
  // { path: '/detail', component: ()=>import('../views/coindetail.vue'),},
]

const router = createRouter({
  history:createWebHashHistory(),
  routes,
})

export default router
